import { MembershipPlanItemProps } from "@/components/membership-plans/membership-plan-item"

export const membershipConfig = [
  {
    membershipPlan: "individual",
    title: "Particular",
    description: "Ideal para <b>dueños directos</b>.",
    perks: [
      { name: "Hasta 2 anuncios GRATIS" },
      { name: "Posicionamiento orgánico" },
    ],
    cta: "Anuncia GRATIS",
  },
  {
    membershipPlan: "professional",
    title: "Profesional",
    description: "Ideal para <b>agentes profesionales</b>.",
    perks: [
      { name: "Anuncios sin publicidad" },
      { name: "Posicionamiento orgánico" },
      { name: "Perfil Profesional" },
    ],
    cta: "Prueba 1 mes GRATIS",
    icon: { src: "/icons/noun-user-professional.svg", size: 24 },
  },
  {
    membershipPlan: "enterprise",
    title: "Empresarial",
    description: "Ideal para <b>inmobiliarias</b> o <b>constructoras</b>.",
    perks: [
      { name: "Anuncios sin publicidad" },
      { name: "Posicionamiento orgánico" },
      {
        name: "Perfil Inmobiliaria/Constructora",
        children: ["Cuenta por Agente", "Dashboard Empresarial"],
      },
    ],
    cta: "Prueba 1 mes GRATIS",
    icon: { src: "/icons/noun-company-enterprise.svg", size: 32 },
  },
] satisfies Omit<MembershipPlanItemProps, "action" | "onMembershipPlanSelect">[]
